import { render, staticRenderFns } from "./ticketAirfare.vue?vue&type=template&id=944e82c8&scoped=true"
import script from "./ticketAirfare.vue?vue&type=script&lang=js"
export * from "./ticketAirfare.vue?vue&type=script&lang=js"
import style0 from "./ticketAirfare.vue?vue&type=style&index=0&id=944e82c8&prod&lang=scss&scoped=true"
import style1 from "./ticketAirfare.vue?vue&type=style&index=1&id=944e82c8&prod&lang=scss&scoped=true"
import style2 from "./ticketAirfare.vue?vue&type=style&index=2&id=944e82c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "944e82c8",
  null
  
)

export default component.exports