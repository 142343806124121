<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">机票费用</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >机票费用</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">人员编码:</div>
          <el-input v-model="userCode" placeholder="人员编码" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">人员名称:</div>
          <el-input v-model="userName" placeholder="人员名称" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">护照号:</div>
          <el-input v-model="passportCode" placeholder="护照号" />
        </div>
        <div class="screen-input-box">
          <div class="title-box">国籍:</div>
          <el-select
            v-model="nationality"
            class="card-select"
            placeholder="国籍"
            filterable
            clearable
          >
            <el-option
              v-for="item in Countries"
              :key="item.ID"
              :label="item.CountryNameCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">项目名称:</div>
          <el-select
            v-model="projectID"
            class="card-select"
            placeholder="项目名称"
            @change="changeProjectID"
            filterable
            clearable
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">用工单位:</div>
          <el-select
            v-model="companyID"
            class="card-select"
            placeholder="用工单位"
            filterable
            clearable
          >
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">机票状态:</div>
          <el-select
            v-model="ticketStatus"
            class="card-select"
            placeholder="机票状态"
            filterable
            clearable
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">飞行路线:</div>
          <el-select
            v-model="airTicketRoute"
            clearable
            placeholder="飞行路线"
            filterable
          >
            <el-option
              v-for="item in ticketGroupList"
              :key="item.ID"
              :label="`${item.DeparatureCityName}——${item.ArrivalCityName}`"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">审批状态:</div>
          <el-select
            v-model="auditStatus"
            class="card-select"
            placeholder="请选择审批状态"
            filterable
            clearable
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">出票机构:</div>
          <el-select
            v-model="ticketAgency"
            class="card-select"
            placeholder="请选择出票机构"
            filterable
            clearable
          >
            <el-option
              v-for="item in ticketAgencyList"
              :key="item.ID"
              :label="item.NameinChinese"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">SPONSOR:</div>
          <el-select
            v-model="sponsor"
            class="card-select"
            placeholder="SPONSOR"
            filterable
            clearable
          >
            <el-option
              v-for="item in options6"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">付款状态:</div>
          <el-select
            v-model="paymentStatus"
            class="card-select"
            placeholder="SPONSOR"
            filterable
            clearable
          >
            <el-option
              v-for="item in options5"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">航线类型:</div>
          <el-select
            v-model="internationalOrDomestic"
            class="card-select"
            placeholder="请选择审批状态"
            filterable
            clearable
          >
            <el-option
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="screen-input-box">
          <div class="title-box">沙特起飞日期:</div>
          <el-date-picker
            v-model="daterange1"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="screen-input-box">
          <div class="title-box">沙特到达日期:</div>
          <el-date-picker
            v-model="daterange2"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="screen-input-box">
          <div class="title-box">申请日期:</div>
          <el-date-picker
            v-model="daterange3"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="screen-input-box">
          <div class="title-box">出票日期:</div>
          <el-date-picker
            v-model="daterange4"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="screen-input-box">
          <div class="title-box">付款日期:</div>
          <el-date-picker
            v-model="daterange5"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getTicketFares(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre" @click="dialogVisible2 = true">
          <i class="fa fa-upload"></i>导入
        </button>
        <button class="btn-pre" @click="exportTicketFare">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :empty-text="loading ? '加载中...' : '暂无数据'"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="EmployeeCode" label="员工编码" width="80" />
          <el-table-column prop="EmployeeName" label="员工姓名" width="80" />
          <el-table-column prop="EmployeeNameEN" label="拼音姓名" width="120" />
          <el-table-column prop="NationalityName" label="国籍" width="80" />
          <el-table-column prop="PassportCode" label="护照号" width="90" />
          <el-table-column prop="ProjectID" label="项目名称" width="90">
            <template slot-scope="scope">
              {{ scope.row.ProjectID | projectFormater(projectList) }}
            </template>
          </el-table-column>

          <el-table-column prop="CreateDate" label="用工单位" width="90">
            <template slot-scope="scope">
              {{ scope.row.CompanyID | companyFormater(companyList) }}s
            </template>
          </el-table-column>

          <el-table-column prop="TicketReference" label="机票编号" width="90">
          </el-table-column>
          <el-table-column
            prop="OneWayorRoundTrip"
            label="One Way/Round-Trip"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.OneWayorRoundTrip | labelFormater(options3) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="CreateDate"
            label="申请日期"
            width="100"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.CreateDate | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column
            prop="InternationalorDomestic"
            label="航线类型"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.InternationalorDomestic | labelFormater(options4) }}
            </template>
          </el-table-column>
          <el-table-column prop="AirTicketRoute" label="飞行路线" width="150">
            <template slot-scope="scope">
              {{ scope.row.AirTicketRoute | ticketFormater(ticketGroupList) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="DeparatureTimefromKSA"
            label="沙特起飞时间"
            sortable
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.DeparatureTimefromKSA">{{
                scope.row.DeparatureTimefromKSA | tableDateFrom2
              }}</span>
              <span v-else>{{
                scope.row.DeparatureDatefromKSA | tableDateFrom
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ArrivalTimeatKSA"
            label="沙特到达时间"
            sortable
            width="120"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.ArrivalTimeatKSA">{{
                scope.row.ArrivalTimeatKSA | tableDateFrom2
              }}</span>
              <span v-else>{{
                scope.row.ArrivalDateatKSA | tableDateFrom
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="AirlineCode" label="航司信息" width="100">
          </el-table-column>
          <el-table-column label="出票机构" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.TicketFareInfo">{{
                scope.row.TicketFareInfo.TicketAgency
                  | ticketAgencyFormater(ticketAgencyList)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="SPONSOR" label="SPONSOR" width="100">
            <template slot-scope="scope">
              {{ scope.row.SPONSOR | labelFormater(options6) }}
            </template>
          </el-table-column>
          <el-table-column label="机票费用(RMB)" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.TicketFareInfo">{{
                scope.row.TicketFareInfo.TicketFare
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税前费用(SR)" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.TicketFareInfo">{{
                scope.row.TicketFareInfo.TicketFareWithoutVATSR
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="发票金额(SR)" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.TicketFareInfo">{{
                scope.row.TicketFareInfo.TicketFareVATSR
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="分割项目费用" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.TicketFareInfo">{{
                scope.row.TicketFareInfo.DividingAmount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="付款日期" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.TicketFareInfo">
                {{ scope.row.TicketFareInfo.PaymentDate | tableDateFrom }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="付款状态" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.TicketFareInfo">
                {{
                  scope.row.TicketFareInfo.PaymentStatus
                    | labelFormater(options5)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="VisaExpiryDate" label="机票状态" width="100">
            <template slot-scope="scope">
              {{ scope.row.TicketStatus | labelFormater(options1) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="TicketIssueDate"
            label="出票日期"
            sortable
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.TicketIssueDate | tableDateFrom }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <i @click="query(scope.row)" class="fa fa-search"></i>
              <i
                class="fa fa-edit"
                @click="edit(scope.row)"
                v-if="powers.update"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="getTicketFares(1)"
          @current-change="getTicketFares"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="机票费用"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main">
        <TicketAirfare
          @update="getTicketFares"
          @close="handleClose"
          ref="ticketAirfare"
        ></TicketAirfare>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
        <el-button
          class="pop-save"
          :disabled="disabled || EditState == 2"
          @click="save"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="导入机票订购"
      :visible.sync="dialogVisible2"
      width="600px"
      :before-close="handleClose2"
      top="50px"
      class="dialog"
    >
      <div class="text-box">
        <div class="text-title">模板文件</div>
        <div class="text-name" @click="getDownloadUrl(downloadUrl)">
          机票订购导入模板
        </div>
      </div>
      <div class="upload-box2">
        <div class="left">
          <div v-if="name2" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name2 }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment"
          ref="uploadRef"
        >
          <button class="btn-up">
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose2">关闭</el-button>
        <el-button class="pop-save pop-right" @click="importTicketFare"
          >导入</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="编辑机票状态"
      :visible.sync="dialogVisible3"
      width="600px"
      :before-close="handleClose3"
      top="50px"
      class="dialog"
    >
      <div class="select-box">
        <div class="text-title">机票状态:</div>
        <el-select
          v-model="disTicketStatus"
          class="card-select"
          placeholder="机票状态"
          filterable
          clearable
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose3">关闭</el-button>
        <el-button class="pop-save pop-right" @click="updateTicketStatus"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getProjectsChildren,
  uploadAttachment,
} from "@/api/user";

import {
  getAirlinesAll,
  getTicketFares,
  getGeneralBasic,
  getTicketFaresItem,
  exportTicketFare,
  importTicketFare,
  importTicketFareTemplate,
  updateTicketStatus,
  getGovernmentSystemDicAll,
} from "@/api/table";
import TicketAirfare from "@/components/tables/ticketAirfare.vue";
export default {
  filters: {
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : null;
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : null;
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : null;
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    tableDateFrom2(data) {
      if (data) {
        data = data.slice(0, 16);
      }
      return data;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value == id;
      });
      return item ? item.label : null;
    },

    ticketFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item
        ? `${item.DeparatureCityName}——${item.ArrivalCityName}`
        : null;
    },

    ticketAgencyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID == id;
      });
      return item ? item.NameinChinese : null;
    },
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑
      dialogVisible3: false,
      disabled: false,
      options1: [
        {
          value: 0,
          label: "Pending",
        },
        {
          value: 1,
          label: "Issued",
        },
        {
          value: 2,
          label: "Rescheduled",
        },
        {
          value: 3,
          label: "Opened",
        },
        {
          value: 4,
          label: "Canceled",
        },
      ],
      options2: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "审核中",
        },
        {
          value: 3,
          label: "审核未通过",
        },
        {
          value: 4,
          label: "驳回",
        },
      ],

      options3: [
        {
          label: "One Way",
          value: 1,
        },
        {
          label: "Round-Trip",
          value: 2,
        },
      ],
      options4: [
        {
          label: "国内",
          value: 1,
        },
        {
          label: "国际",
          value: 2,
        },
      ],
      options5: [
        {
          value: 1,
          label: "Pending",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],
      options6: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],

      projectList: [],
      companyList: [],
      departmentList: [],
      companyList: [],
      Countries: [],
      subProjectList: [],
      ticketGroupList: [],
      ticketAgencyList: [],
      page: 1,
      size: 10,
      total: 10,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },

      EditState: 1,

      loading: true,

      ticketAgency: null,
      sponsor: null,
      paymentStatus: null,

      ticketStatus: 0,
      airTicketRoute: null,
      auditStatus: null,
      projectID: null,
      subProjectID: null,
      companyID: null,
      departureStartDate: null,
      departureEndDate: null,
      nationality: null,
      userCode: null,
      userName: null,
      passportCode: null,
      visaType: null,
      arrivalStartDate: null,
      arrivalEndDate: null,
      internationalOrDomestic: null,
      daterange1: null,
      daterange2: null,
      daterange3: null,
      daterange4: null,
      daterange5: null,
      dialogVisible2: false,
      name2: null,
      downloadUrl: null,
      disTicketStatus: null,
      KeyID: null,
    };
  },
  components: { TicketAirfare },
  methods: {
    changeProjectID() {
      this.subProjectID = null;
      this.subProjectList = [];
      this.getProjectsChildren();
    },
    getProjectsChildren() {
      if (!this.projectID) {
        return;
      }
      getProjectsChildren({ id: this.projectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
    handleClose() {
      this.$refs.ticketAirfare.clearData();
      this.dialogVisible = false;
      this.Id = 0;
      this.ParentId = 0;
      this.DepartmentId = 0;
      this.disabled = false;
      this.EditState = 1;
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.name2 = null;
    },
    handleClose3() {
      this.dialogVisible3 = false;
    },
    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
      setTimeout(() => {
        this.$refs.ticketChange.getNew();
      }, 100);
    },
    edit(data) {
      this.dialogVisible = true;
      this.EditState = data.EditState;
      this.getTicketFaresItem(data.KeyID);
    },
    query(data) {
      this.dialogVisible = true;
      this.getTicketFaresItem(data.KeyID);
      this.disabled = true;
    },

    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }
      const item = this.$store.state.userPower.find((item) => {
        return item.Id === 337;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取列表
    getTicketFares(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        ticketStatus: this.ticketStatus,
        airTicketRoute: this.airTicketRoute,
        auditStatus: this.auditStatus,
        projectID: this.projectID,
        companyID: this.companyID,
        departureStartDate: this.daterange1 ? this.daterange1[0] : "",
        departureEndDate: this.daterange1 ? this.daterange1[1] : "",
        nationality: this.nationality,
        userCode: this.userCode,
        userName: this.userName,
        passportCode: this.passportCode,
        internationalOrDomestic: this.internationalOrDomestic,
        valStartDate: this.daterange2 ? this.daterange2[0] : "",
        arrivalEndDate: this.daterange2 ? this.daterange2[1] : "",
        applyStartDate: this.daterange3 ? this.daterange3[0] : "",
        applyEndDate: this.daterange3 ? this.daterange3[1] : "",
        ticketIssueStartDate: this.daterange4 ? this.daterange4[0] : "",
        ticketIssueEndDate: this.daterange4 ? this.daterange4[1] : "",

        ticketAgency: this.ticketAgency,
        sponsor: this.sponsor,
        paymentStatus: this.paymentStatus,
        paymentStartDate: this.daterange5 ? this.daterange5[0] : "",
        paymentEndDate: this.daterange5 ? this.daterange5[1] : "",
      };
      getTicketFares(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //保存
    save() {
      this.$refs.ticketAirfare.addCostAllocation(1);
    },
    //发布
    release() {
      this.$refs.ticketAirfare.addCostAllocation(2);
    },
    //表单详情
    getTicketFaresItem(key) {
      getTicketFaresItem({
        key,
      }).then((res) => {
        if (res.status === 200) {
          this.$refs.ticketAirfare.getEditData(res.response);
        }
      });
    }, //导出
    exportTicketFare() {
      const data = {
        ticketStatus: this.ticketStatus,
        airTicketRoute: this.airTicketRoute,
        auditStatus: this.auditStatus,
        projectID: this.projectID,
        companyID: this.companyID,
        departureStartDate: this.daterange1 ? this.daterange1[0] : "",
        departureEndDate: this.daterange1 ? this.daterange1[1] : "",
        nationality: this.nationality,
        userCode: this.userCode,
        userName: this.userName,
        passportCode: this.passportCode,
        internationalOrDomestic: this.internationalOrDomestic,
        valStartDate: this.daterange2 ? this.daterange2[0] : "",
        arrivalEndDate: this.daterange2 ? this.daterange2[1] : "",
        applyStartDate: this.daterange3 ? this.daterange3[0] : "",
        applyEndDate: this.daterange3 ? this.daterange3[1] : "",
        ticketIssueStartDate: this.daterange4 ? this.daterange4[0] : "",
        ticketIssueEndDate: this.daterange4 ? this.daterange4[1] : "",
        ticketAgency: this.ticketAgency,
        sponsor: this.sponsor,
        paymentStatus: this.paymentStatus,
        paymentStartDate: this.daterange5 ? this.daterange5[0] : "",
        paymentEndDate: this.daterange5 ? this.daterange5[1] : "",
      };
      exportTicketFare(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },
    getDownloadUrl(url) {
      location.href = url;
    },

    //导入
    importTicketFare() {
      const data = {
        Path: this.uploadUrl,
        KeyID: this.KeyID,
      };
      importTicketFare(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose2();
          this.getTicketFares();
        }
      });
    },

    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name2 = data.name;
          this.uploadUrl = res.response;
          console.log("this.name2", this.name2);
        }
      });
    },

    changedialogVisible3(data) {
      this.dialogVisible3 = true;
      this.KeyID = data.KeyID;
      this.disTicketStatus = data.TicketStatus;
    },

    updateTicketStatus() {
      updateTicketStatus({
        KeyID: this.KeyID,
        TicketStatus: this.disTicketStatus,
      }).then((res) => {
        if (res.status === 200) {
          this.$message.success("修改成功");
          this.handleClose3();
          this.getTicketFares();
        }
      });
    },
  },

  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.Countries = res.response.Countries;
      }
    });
    //全部飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.ticketGroupList = res.response;
      }
    });
    //获取全部出票机构
    getGovernmentSystemDicAll({ typeID: 3 }).then((res) => {
      if (res.status === 200) {
        this.ticketAgencyList = res.response;
      }
    });
    this.getTicketFares();
    this.getButtonPower();
    importTicketFareTemplate().then((res) => {
      if (res.status === 200) {
        this.downloadUrl = "https://hrms.baishulai.com/" + res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 70px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.progress-box {
  height: 24px;
  display: flex;
  width: 100%;
  align-items: center;
  .el-progress {
    width: 100%;
  }
  // margin-top: 24px;
}
.upload-box2 {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
.bnt-span {
  color: #519fff;
  cursor: pointer;
}
.select-box {
  display: flex;
  align-items: center;
  .el-select {
    margin-left: 15px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  // .el-table__body,
  // .el-table__footer,
  // .el-table__header {
  //   table-layout: auto;
  //   .yincang {
  //     width: 100px !important;
  //     min-width: 100px !important;
  //     max-width: 100px !important;
  //     .cell {
  //       width: 100%;
  //       box-sizing: border-box !important;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       word-break: inherit !important;
  //       white-space: nowrap !important;
  //     }
  //   }
  // }
  // .el-table__header {
  //   .yincang {
  //     width: 100px !important;
  //     min-width: 100px !important;
  //     max-width: 100px !important;
  //     .cell {
  //       width: 100%;
  //       box-sizing: border-box !important;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       word-break: inherit !important;
  //       white-space: nowrap !important;
  //     }
  //   }
  // }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
