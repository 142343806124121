<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center">
            Ticket Reference <br />
            机票编号
          </td>
          <td class="text-center" colspan="2">
            Employee Name<br />
            拼音姓名
          </td>
          <td class="text-center">One Way/ Round-Trip</td>
          <td class="text-center" colspan="2">
            Air Ticket Route<br />
            飞行路线
          </td>
          <td class="text-center">
            Transit City<br />
            中转城市
          </td>
        </tr>
        <tr>
          <td class="text-center">{{ TicketReference }}</td>
          <td class="text-center" colspan="2">{{ EmployeeNameEN }}</td>
          <td class="text-center">
            {{ OneWayorRoundTrip | labelFormater(options2) }}
          </td>
          <td class="text-center" colspan="2">
            {{ AirTicketRoute | ticketFormater(ticketGroupList) }}
          </td>
          <td class="text-center">
            <el-input v-model="TransitCity" placeholder="请输入 Enter" />
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Deparature Date from KSA<br />
            沙特起飞日期
          </td>
          <td class="text-center">
            Deparature Time from KSA<br />
            沙特起飞时间
          </td>
          <td class="text-center">Aarrival Date at KSA <br />沙特到达日期</td>
          <td class="text-center">Aarrival Time at KSA <br />沙特到达时间</td>
          <td class="text-center">
            Airline<br />
            Code 航司信息
          </td>
          <td class="text-center">
            Ticket Issue Date<br />
            出票日期
          </td>
          <td class="text-center">
            Ticker Agency<br />
            出票机构
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="DeparatureDatefromKSA"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-time-picker
              value-format="hh:mm"
              format="hh:mm"
              v-model="DeparatureTimefromKSA"
              placeholder="请选择 Please select"
            >
            </el-time-picker>
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="ArrivalDateatKSA"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-time-picker
              value-format="hh:mm"
              format="hh:mm"
              v-model="ArrivalTimeatKSA"
              placeholder="请选择 Please select"
            >
            </el-time-picker>
          </td>
          <td class="text-center">
            <el-input v-model="AirlineCode" placeholder="请输入 Enter" />
          </td>

          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="TicketIssueDate"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-select
              v-model="TicketAgency"
              placeholder="请选择 Please select"
            >
              <el-option
                v-for="item in ticketAgencyList"
                :key="item.ID"
                :label="item.NameinChinese"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center">Ticket Fare <br />机票费用(RMB)</td>
          <td class="text-center">Ticket Fare Without VAT (SR)</td>
          <td class="text-center">Ticket Fare VAT (SR)</td>
          <td class="text-center">Invoice Amount (SR)</td>
          <td class="text-center">
            Dividing Amount<br />
            分割项目费用
          </td>
          <td class="text-center">
            Income SAR<br />
            收益差额
          </td>
          <td class="text-center">
            Payment Date<br />
            付款日期
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <el-input v-model="TicketFare" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input
              v-model="TicketFareWithoutVATSR"
              placeholder="请输入 Enter"
            />
          </td>
          <td class="text-center">
            <el-input v-model="TicketFareVATSR" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="InvoiceAmountSR" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="DividingAmount" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-input v-model="IncomeSAR" placeholder="请输入 Enter" />
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="PaymentDate"
              type="date"
              placeholder="请选择 Please select"
            >
            </el-date-picker>
          </td>
        </tr>
        <tr>
          <td class="text-center">
            Payment Status<br />
            付款状态
          </td>
          <td class="text-center">
            <el-select
              v-model="PaymentStatus"
              placeholder="请选择 Please select"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            Remark<br />
            备注
          </td>
          <td class="text-center" colspan="5">
            <el-input v-model="Remark" placeholder="请输入 Enter" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {
  getAirlinesAll,
  editTicketFareItem,
  getGovernmentSystemDicAll,
} from "@/api/table";
import { compareDates } from "@/utils/date";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    ticketFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item
        ? `${item.DeparatureCityName}——${item.ArrivalCityName}`
        : null;
    },
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
  },
  data() {
    return {
      KeyID: null,
      TicketReference: null,
      TransitCity: null,
      DeparatureDatefromKSA: null,
      DeparatureTimefromKSA: null,
      ArrivalDateatKSA: null,
      ArrivalTimeatKSA: null,
      AirlineCode: null,
      TicketIssueDate: null,
      TicketAgency: null,
      TicketFare: null,
      TicketFareWithoutVATSR: null,
      TicketFareVATSR: null,
      InvoiceAmountSR: null,
      DividingAmount: null,
      IncomeSAR: null,
      PaymentDate: null,
      PaymentStatus: null,
      Remark: null,
      EmployeeNameEN: null,
      OneWayorRoundTrip: null,
      AirTicketRoute: null,
      ticketAgencyList: [],
      options1: [
        {
          value: 1,
          label: "Pending",
        },
        {
          value: 2,
          label: "Paid",
        },
      ],

      options2: [
        {
          label: "One Way",
          value: 1,
        },
        {
          label: "Round-Trip",
          value: 2,
        },
      ],
      ticketGroupList: [],

      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.ArrivalDateatKSA) {
            return time.getTime() >= new Date(this.ArrivalDateatKSA).getTime();
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.DeparatureDatefromKSA) {
            return (
              time.getTime() <=
              new Date(this.DeparatureDatefromKSA).getTime() - 8.64e7
            );
          }
        },
      },
    };
  },

  methods: {
    addCostAllocation() {
      const data = {
        KeyID: this.KeyID,
        TicketReference: this.TicketReference,
        TransitCity: this.TransitCity,
        DeparatureDatefromKSA: this.DeparatureDatefromKSA,
        DeparatureTimefromKSA:
          this.DeparatureDatefromKSA + " " + this.DeparatureTimefromKSA,
        ArrivalDateatKSA: this.ArrivalDateatKSA,
        ArrivalTimeatKSA: this.ArrivalDateatKSA + " " + this.ArrivalTimeatKSA,
        AirlineCode: this.AirlineCode,
        TicketIssueDate: this.TicketIssueDate,
        TicketAgency: this.TicketAgency,
        TicketFare: this.TicketFare,
        TicketFareWithoutVATSR: this.TicketFareWithoutVATSR,
        TicketFareVATSR: this.TicketFareVATSR,
        InvoiceAmountSR: this.InvoiceAmountSR,
        DividingAmount: this.DividingAmount,
        IncomeSAR: this.IncomeSAR,
        PaymentDate: this.PaymentDate,
        PaymentStatus: this.PaymentStatus,
        Remark: this.Remark,
      };

      let isTrue = true;
      if (data.DeparatureTimefromKSA && data.ArrivalTimeatKSA) {
        isTrue = compareDates(
          data.DeparatureTimefromKSA,
          data.ArrivalTimeatKSA
        );
      }
      if (!isTrue) {
        this.$message.warning("沙特起飞时间必须小于沙特到达时间");
        return;
      }
      editTicketFareItem(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("编辑成功");
          this.$emit("update");
          this.$emit("close");
        }
      });
    },
    getEditData(params) {
      console.log("params", params);
      this.KeyID = params.KeyID;
      this.TicketReference = params.TicketReference;
      this.TransitCity = params.TransitCity;
      this.DeparatureDatefromKSA = params.DeparatureDatefromKSA
        ? params.DeparatureDatefromKSA.slice(0, 10)
        : null;
      this.DeparatureTimefromKSA = params.DeparatureTimefromKSA
        ? params.DeparatureTimefromKSA.slice(12, 19)
        : null;
      this.ArrivalDateatKSA = params.ArrivalDateatKSA
        ? params.ArrivalDateatKSA.slice(0, 10)
        : null;
      this.ArrivalTimeatKSA = params.ArrivalTimeatKSA
        ? params.ArrivalTimeatKSA.slice(12, 19)
        : null;
      this.AirlineCode = params.AirlineCode;
      this.TicketIssueDate = params.TicketIssueDate;
      if (params.TicketFareInfo) {
        this.TicketAgency = params.TicketFareInfo.TicketAgency;
        this.TicketFare = params.TicketFareInfo.TicketFare;
        this.TicketFareWithoutVATSR =
          params.TicketFareInfo.TicketFareWithoutVATSR;
        this.TicketFareVATSR = params.TicketFareInfo.TicketFareVATSR;
        this.InvoiceAmountSR = params.TicketFareInfo.InvoiceAmountSR;
        this.DividingAmount = params.TicketFareInfo.DividingAmount;
        this.IncomeSAR = params.TicketFareInfo.IncomeSAR;
        this.PaymentDate = params.TicketFareInfo.PaymentDate;
        this.PaymentStatus = params.TicketFareInfo.PaymentStatus;
        this.Remark = params.TicketFareInfo.Remark;
      }
      this.EmployeeNameEN = params.EmployeeNameEN;
      this.OneWayorRoundTrip = params.OneWayorRoundTrip;
      this.AirTicketRoute = params.AirTicketRoute;
    },
    clearData() {
      this.KeyID = null;
      this.TicketReference = null;
      this.TransitCity = null;
      this.DeparatureDatefromKSA = null;
      this.DeparatureTimefromKSA = null;
      this.ArrivalDateatKSA = null;
      this.ArrivalTimeatKSA = null;
      this.AirlineCode = null;
      this.TicketIssueDate = null;
      this.TicketAgency = null;
      this.TicketFare = null;
      this.TicketFareWithoutVATSR = null;
      this.TicketFareVATSR = null;
      this.InvoiceAmountSR = null;
      this.DividingAmount = null;
      this.IncomeSAR = null;
      this.PaymentDate = null;
      this.PaymentStatus = null;
      this.Remark = null;
      this.EmployeeNameEN = null;
      this.OneWayorRoundTrip = null;
      this.AirTicketRoute = null;
    },
  },
  created() {
    //全部飞行路线
    getAirlinesAll().then((res) => {
      if (res.status === 200) {
        this.ticketGroupList = res.response;
      }
    });
    //获取全部出票机构
    getGovernmentSystemDicAll({ typeID: 3 }).then((res) => {
      if (res.status === 200) {
        console.log("111", res, res.response);
        this.ticketAgencyList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 200px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-red {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #ff0000;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  justify-content: center;
}
.shenpi-color {
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>